import { Flex, Text, Button, useBreakpointValue, Link } from '@chakra-ui/react'

import { Icon } from '@miimosa/design-system'

const Header = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'lg' })

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      h="auto"
      w="full"
      px={{ base: 4, md: 10 }}
      alignItems="center"
      justifyContent="space-between"
      py={{ base: 5, md: 6 }}
      rowGap={2}
      position="sticky"
      top={0}
      bg="white"
      zIndex={100}
    >
      <Flex position="relative" h="full" w={{ base: 'auto', md: '150px' }} alignItems="center" justifyContent="center">
        <Link href="#">
          <Icon name={'LogoMousline'} size="xl" h="auto" />
        </Link>
      </Flex>
      <Flex direction="row" columnGap={6}>
        <Link href="#program">
          <Text size="md" color="gray.200">
            Découvrir le programme
          </Text>
        </Link>
        <Link href="#engagements">
          <Text size="md" color="gray.200">
            Nos engagements
          </Text>
        </Link>
        <Link href="#projects">
          <Text size="md" color="gray.200">
            Les projets
          </Text>
        </Link>
      </Flex>
      {!isMobile && (
        <Flex alignItems="center" w="150px" justifyContent="center">
          <Link href="#form">
            <Button bg="red" color="white">
              Je candidate
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  )
}

export default Header
