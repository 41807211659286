import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: { base: '1.125rem' }, //18px
    lineHeight: { base: '1.25rem' },
  },
  md: {
    fontSize: { base: '1.25rem' }, //20px
    lineHeight: { base: '1.5rem' },
  },
  lg: {
    fontSize: { base: '2.1875rem' }, //35px
    lineHeight: { base: '2.55rem' },
  },
  xl: {
    fontSize: { base: '2.5rem' }, //40px
    lineHeight: { base: '2.55rem' },
  },
}

export const Heading = {
  sizes,
  variants: {},
  // The default size and variant values
  defaultProps: {
    size: 'md',
  },
}
