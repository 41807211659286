import { Flex, Button, Text, Link } from '@chakra-ui/react'

import { Icon } from '@miimosa/design-system'

const Footer = () => {
  return (
    <Flex
      direction="column"
      w="full"
      h="auto"
      px={{ base: 8, md: 16 }}
      py={10}
      bg="red"
      alignItems={'center'}
      rowGap={6}
    >
      <Flex w="full" direction={{ base: 'column', md: 'row' }} rowGap={10}>
        <Flex w="full" direction="column" rowGap={6} justifyContent="center" alignItems="center">
          <Link href="https://www.miimosa.com" isExternal>
            <Icon name="Logo2" color="white" size="xl" h="62px" />
          </Link>
          <Flex direction="row" columnGap={2}>
            <Link href="https://www.linkedin.com/company/miimosa" isExternal>
              <Icon name="LinkedIn" color="white" size="4xs" />
            </Link>
            <Link href="https://www.facebook.com/MiiMOSAfr" isExternal>
              <Icon name="Facebook" color="white" size="4xs" />
            </Link>
            <Link href="https://www.instagram.com/MiiMOSA_fr" isExternal>
              <Icon name="Instagram" color="white" size="4xs" />
            </Link>
            <Link href="https://twitter.com/MiiMOSA_fr" isExternal>
              <Icon name="Twitter" color="white" size="4xs" />
            </Link>
          </Flex>
          <Link href="https://blog.miimosa.com/" isExternal>
            <Button bg="white" w="fit-content" borderRadius={0}>
              <Text color="red">Le blog</Text>
            </Button>
          </Link>
        </Flex>
        <Flex w="full" direction="column" rowGap={6} justifyContent="center" alignItems="center">
          <Icon name="LogoMousline" size="xl" h="auto" />
          <Flex direction="row" columnGap={2}>
            <Link href="https://fr.linkedin.com/company/mousline" isExternal>
              <Icon name="LinkedIn" color="white" size="4xs" />
            </Link>
            <Link href="https://www.instagram.com/mousline_officiel" isExternal>
              <Icon name="Instagram" color="white" size="4xs" />
            </Link>
          </Flex>
          <Flex direction="row" columnGap={4}>
            <Link href="https://www.mousline.fr" isExternal>
              <Button bg="white" w="fit-content" borderRadius={0}>
                <Text color="red">Mousline</Text>
              </Button>
            </Link>
            <Link href="https://www.mouslinepro.fr" isExternal>
              <Button bg="white" w="fit-content" borderRadius={0}>
                <Text color="red">Mousline Pro</Text>
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" columnGap={2}>
        <Text color="white">© 2024</Text>
        <Text color="white">|</Text>
        <Link href="https://www.miimosa.com" isExternal>
          <Text color="white">MiiMOSA</Text>
        </Link>
        <Text color="white">|</Text>
        <Link
          href="https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page=24"
          isExternal
        >
          <Text color="white">Mentions légales & CGU</Text>
        </Link>
        <Text color="white">|</Text>
        <Link href="https://miimosa.com/fr/cookies" isExternal>
          <Text color="white">Cookies</Text>
        </Link>
      </Flex>
    </Flex>
  )
}

export default Footer
