import '../styles/globals.css'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { register } from 'timeago.js'

import { s3ImageURL } from '@miimosa/common'
import { timeagoFR } from '@miimosa/design-system/lib/i18n/timeago'

import { theme } from '../themes'

import Layout from '@components/Layout'

// register your locale with timeago
register('fr', timeagoFR)

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" href="/favicon.png" sizes="64x64" />
        <title>Mousline s’engage pour la filière pomme de terre </title>
        <meta
          name="description"
          content="Avec MiiMOSA, Mousline soutient et accompagne ses agriculteurs pour le financement de projets en faveur de la transition agricole et alimentaire."
        />
        <meta property="og:title" content="Mousline s’engage pour la filière pomme de terre " key="title" />
        <meta
          property="og:description"
          content="Avec MiiMOSA, Mousline soutient et accompagne ses agriculteurs pour le financement de projets en faveur de la transition agricole et alimentaire."
          key="description"
        />
        <meta property="og:image" content={s3ImageURL('hero.jpeg')} key="image" />
      </Head>
      <Sentry.ErrorBoundary showDialog={false}>
        <ChakraProvider theme={theme}>
          <Layout>{<Component {...pageProps} />}</Layout>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}

// withTranslation make the t params available in the components/page
// this is a HOC (higher order component)
export default appWithTranslation(MyApp)
