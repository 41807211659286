import { theme as baseTheme, defineStyle, extendTheme } from '@chakra-ui/react'
import localFont from 'next/font/local'

import { Heading } from './heading'
import { Text } from './text'

const GothamMedium = localFont({ src: '../../public/fonts/Gotham-Medium.otf', display: 'swap' })
const KitchenCupBoardRegular = localFont({ src: '../../public/fonts/KitchenCupboard-Regular.ttf', display: 'swap' })

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

// Making sure chakra base sizes are not present
baseTheme.components.Heading.sizes = undefined
baseTheme.components.Input.sizes = undefined
baseTheme.components.Avatar.sizes = undefined

const solid = defineStyle({
  _hover: {
    bg: 'null',
    _disabled: {
      bg: 'null',
    },
  },
  _active: {
    bg: 'null',
  },
})

const Button = {
  variants: {
    solid,
  },
}

export const theme = extendTheme({
  components: {
    Text,
    Heading,
    Button,
  },
  breakpoints: {
    '3xl': '106em',
    '4xl': '120em',
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  fonts: {
    heading: `${KitchenCupBoardRegular.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `${GothamMedium.style.fontFamily}, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    red: '#d50911',
    yellow: '#ffe200',
    cream: '#e6dac6',
    gray: {
      200: '#969393',
      800: '#444444',
    },
    green: '#add50b',
    radio: {
      500: '#003F2C',
    },
    checkbox: {
      500: '#ffe200',
    },
  },
})
